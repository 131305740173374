<template>
  <v-container fill-height fluid>
    <v-row align="center"
           justify="center">
      <v-col>

        <v-row align="center"
               justify="center">
          <img src="@/assets/logo-v.png" width="200px" />
        </v-row>
        <v-row justify="center" class="mt-10">
          <h1>VINX cache calculation application</h1>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { VCol, VContainer, VRow } from "vuetify/lib";

export default {
  components: { VCol, VContainer, VRow },
  // path: "/home",
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h1,
h2 {
  font-weight: normal;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}
</style>
